.App {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/homebanner.png');
  background-size: cover;
  background-position: center;
  header{
    height: calc( (178 / 1880) * 100vw);
    padding: 0 36px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    .logo{
      width: calc( (421 / 1920) * 100vw);
      height: calc( (178 / 1920) * 100vw);
    }
    .menuButton{
      cursor: pointer;
      width: calc( (45 / 1920) * 100vw);
      height: calc( (45 / 1920) * 100vw);
    }
  }
  footer{
    position: absolute;
    bottom: calc( (58 / 1920) * 100vw);
    padding: 0 calc( (96 / 1920) * 100vw);
    box-sizing: border-box;
    .social{
      margin-right: calc( (50 / 1920) * 100vw);
      img{
        width: calc( (96 / 1920) * 100vw);
        height: calc( (96 / 1920) * 100vw);
      }
    }
  }
}

 

@media screen and (max-width: 750px) {
  .App {
    background-image: url('../assets/homebanner_mobile.jpg');
    header{
      height: calc( (178 / 1080) * 100vw);
      padding: 0 calc( (48 / 1080) * 100vw);
      .logo{
        width: calc( (421 / 1080) * 100vw);
        height: calc( (178 / 1080) * 100vw);
      }
      .menuButton{
        width: calc( (78 / 1080) * 100vw);
        height: calc( (78 / 1080) * 100vw);
      }
    }
    footer{
      bottom: calc( (58 / 1080) * 100vw);
      padding: 0 calc( (48 / 1080) * 100vw);
      .social{
        margin-right:calc( (48 / 1080) * 100vw);
        img{
          width: calc( (96 / 1080) * 100vw);
          height: calc( (96 / 1080) * 100vw);
        }
      }
    }
  }
}

 
