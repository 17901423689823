.Subpages{
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 30px;
  box-sizing: border-box;
  background-image: url('../assets/bg.png');
  background-repeat: repeat;
  header{
    height: calc( (178 / 1920) * 100vw );
    padding: 0 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    .left{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      .back_icon{
        width: calc( (45 / 1920) * 100vw );
      }
      span{
        font-family: zihun95hao;
        font-weight: normal;
        font-size: calc( (34 / 1920) * 100vw );
        color: #000000;
        line-height: 46px;
        margin-left: 8px;
      }
    }
    .right{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .logo{
        width: calc( (329 / 1920) * 100vw);
      }
    }
    .menuButton{
      margin-left: 50px;
      cursor: pointer;
      width: calc( (45 / 1920) * 100vw);
      height: calc( (45 / 1920) * 100vw);
    }
  }

  .token_content{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: calc( (178 / 1920) * 100vw );
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    
    .banner{
      width: calc( (1536 / 1920) * 100vw );
      max-width: 1536px;
      margin-top: 60px;
      &.pc{
        display: block;
      }
      &.h5{
        display: none;
      }
    }
    .tokenomics.h5{display: none;}
    p{
      width: 100%;
      height: 48px;
      text-align: center;
      font-family: zihun95hao;
      font-weight: normal;
      font-size: 44px;
      color: #000000;
      line-height: 81px;
    }
    div{
      margin-top: 24px;
      span{
        display: block;
        min-width: calc( (425 / 1920) * 100vw );
        height: 121px;
        line-height: 121px;
        font-family: zihun95hao;
        font-weight: 400;
        font-size: calc( (66 / 1920) * 100vw );
        color: #000000;
        border: #000000 2px solid;
        padding: 0 20px;
      }
    }
  }
  .welcome_content{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    padding-top: calc( (178 / 1920) * 100vw );
    .banner{
      position: absolute;
      top: 0;
      z-index: 1;
      width: calc( (1727 / 1920) * 100vw );
      max-width: 1727px;
      &.pc{
        display: block;
      }
      &.h5{
        display: none;
      }
    }
    .placehoder{
      visibility: hidden;
      display: block;
      .banner{
        position: static;
        width: calc( (1727 / 1920) * 100vw );
        max-width: 1727px;
      }
    }
    p{
      margin-top: calc( (105 / 1920 ) * 100vw );
      width: calc( (1433 / 1920 ) * 100vw );
      max-width: 1433px;
      font-family: zihun95hao;
      font-weight: normal;
      font-size: 34px;
      color: #000000;
      line-height: 50px;
      text-align: center;
    }
  }
  .buy_content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    padding-top: calc( (178 / 1920) * 100vw );
    .buy_title{
      width: calc( (345 / 1920) * 100vw );
      max-width: 345px;
      margin-top: calc( (66 / 1920) * 100vw );
    }
    .operate_wrap{
      width: calc( (1322 / 1920) * 100vw );
      max-width: 1322px;
      height: calc( (622 / 1920) * 100vw );
      min-height: 480px;
      margin-top: calc( (70 / 1920) * 100vw );
      background-color: rgba(255, 255, 255, .8);
      border-radius: 16px;
      display: flex;
      justify-content: space-between;
      padding: calc( (50 / 1920) * 100vw );
      box-sizing: border-box;
      .desc{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: calc( (560 / 1920) * 100vw );
        .token_logo{
          width: calc( (333 / 1920) * 100vw );
          max-width: 333px;
          height: calc( (333 / 1920) * 100vw );
          max-height: 333px;
        }
        p{
          width: calc( (410 / 1920) * 100vw );
          max-width: 410px;
          font-size: calc( (46 / 1920) * 100vw );
          font-family: zihun95hao;
          font-weight: normal;
          color: #000000;
          line-height: 50px;
          text-align: center;
          margin: 0;
          @media screen and (min-width: 1920px) {
            font-size: 46px;
          }
        }
      }
    }
    .terminal_wrap{
      display: flex;
      align-items: center;
      width: calc( (737 / 1920) * 100vw );
      max-width: 737px;
      height: auto;
      border-radius: 18px;
      padding: 12px;
      background-color: rgba(0, 0, 0, .1);
    }
  }
}

@keyframes shake{
  0% { -webkit-transform: rotate(6deg); }
  25% {-webkit-transform: rotate(-6deg); }
  50% { -webkit-transform: rotate(4deg); }
  75% {-webkit-transform: rotate(-4deg); }
  100% { -webkit-transform: rotate(0deg); }
} 

.shake{
  animation: shake 3s 1 ease-in-out;
  animation-fill-mode: forwards;
  transform-origin: 50% -10%;
}

#jupiter-terminal{
  width: 100%;
  .mt-2.h-7.pl-3.pr-2{
    display: none;
  }
  .bg-\[\#212128\]{
    /* background-color: #F8F8F8 !important ;
    box-shadow: 0px 0px 22px 2px rgba(0,0,0,0.11);
    margin-bottom: 20px !important; */
    padding: 15px 0;
  }
  .bg-\[\#36373E\]{
    /* background-color: rgba(0, 0, 0, .1) !important ;
    color: #000000 !important; */
  }
  .text-white\/30 {
    /* color: #828282 !important; */
  }
  .bg-\[\#191B1F\]{
    /* background-color: rgba(0, 0, 0, .7) !important ; */
  }
}


@media screen and (max-width: 750px) {
  .Subpages{
    header{
      height: auto;
      padding: calc( (40 / 1080) * 100vw ) calc( (48 / 1080) * 100vw ) 0;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
      .left{
        order: 2;
        padding-top: calc( (30 / 1080) * 100vw );
        .back_icon{
          width: calc( (64 / 1080) * 100vw );
        }
        span{
          font-size: calc( (68 / 1920) * 100vw );
          line-height: calc( (68 / 1920) * 100vw );
        }
      }
      .right{
        order: 1;
        .logo{
          width: calc( (329 / 1080) * 100vw);
        }
      }
      .menuButton{
        margin-left: calc( (45 / 1080) * 100vw);
        cursor: pointer;
        width: calc( (79 / 1080) * 100vw);
        height: calc( (79 / 1080) * 100vw);
      }
    }
    .token_content{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding-top: calc( ((116 + 212) / 1080) * 100vw );
      .banner{
        width: calc( (857 / 1080) * 100vw );
        margin-top: calc( (134 / 1080) * 100vw );
        &.pc{
          display: none;
        }
        &.h5{
          display: block;
        }
      }
      .tokenomics.h5{
        display: block;
        width: calc( (608 / 1080) * 100vw );
      }
      p{
        font-size: calc( (44 / 1080) * 100vw );
        color: #000000;
        line-height: calc( (44 / 1080) * 100vw );
        height: calc( (44 / 1080) * 100vw );
        margin: calc( (104 / 1080) * 100vw ) 0 0;
      }
      div{
        margin-top: calc( (34 / 1080) * 100vw );
        span{
          display: flex;
          width: auto;
          height: calc( (121 / 1080) * 100vw );
          line-height: calc( (121 / 1080) * 100vw );
          font-size: calc( (66 / 1080) * 100vw );
          color: #000000;
          border: #000000 2px solid;
          padding: 0 calc( (50 / 1080) * 100vw );
        }
      }
    }
    .welcome_content{
      .banner{
        &.pc{
          display: none;
        }
        &.h5{
          display: block;
          width: 100%;
          height: calc( (694 / 1080) * 100vw );
          background-image: url(../assets//welcome_banner_mobile.png);
          background-size: calc( (1788 / 1080) * 100vw ) calc( (694 / 1080) * 100vw );
          background-position: center;
          position: relative;
          margin-top: calc( (480 / 1080) * 100vw );
        }
      }
      .placehoder{display: none;}
      p{
        margin-top: calc( (239 / 1080 ) * 100vw );
        width: calc( (723 / 1080 ) * 100vw );
        font-size: calc( (34 / 1080) * 100vw );
        line-height: calc( (50 / 1080) * 100vw );
      }
    }
    .buy_content{
      .buy_title{
        width: calc( (492 / 1080) * 100vw );
        margin-top: calc( (301 / 1080) * 100vw );
      }
      .operate_wrap{
        width: calc( (890 / 1080) * 100vw );
        height: auto;
        margin-top: calc( (86 / 1080) * 100vw );
        display: flex;
        flex-direction: column;
        padding: calc( (50 / 1080) * 100vw );
        box-sizing: border-box;
        .desc{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          .token_logo{
            width: calc( (333 / 1080) * 100vw );
            height: calc( (333 / 1080) * 100vw );
          }
          p{
            width: calc( (410 / 1080) * 100vw );
            font-size: calc( (47 / 1080) * 100vw );
            line-height: calc( (50 / 1080) * 100vw );
          }
        }
      }
      .terminal_wrap{
        width: calc( (737 / 1080) * 100vw );
        height: auto;
      }

      #jupiter-terminal{
        margin-top: calc( (109 / 1080) * 100vw );
        .mt-2.h-7.pl-3.pr-2{
          display: none;
        }
        .bg-\[\#212128\]{
          /* background-color: #F8F8F8 !important ;
          box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.08);
          margin-bottom: 10px !important; */
          padding: 0 0;
        }
        .bg-\[\#36373E\]{
          /* background-color: rgba(0, 0, 0, .11) !important ;
          color: #000000 !important; */
        }
        .text-white\/30 {
          /* color: #828282 !important; */
        }
        .bg-\[\#191B1F\]{
          /* background-color: rgba(0, 0, 0, .7) !important ; */
          height: calc( (73 / 1080) * 100vw ) !important;
          .p-5{
            padding: calc( (20 / 1080) * 100vw );
          }
        }
      }

    }
  }
}