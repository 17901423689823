.menuWrap{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  &.show{
    left: 0;
  }
  &.hidden{
    left: 100vw;
  }
}
.menu_bg{
  width: 100vw;
  height: 100vh;
  background: #0D0D0D;
  opacity: 0.7;
  position: fixed;
  top: 0;
  left: 0;
  &.hidden{
    display: none;
  }
  &.show{
    display: block;
  }
}
.menu{
  &.h5.show{display: none;}
  &.pc.show{display: block;}
  position: fixed;
  top: calc( (-30 / 1920) * 100vw);
  transition: all .4s ease-in-out;
  &.show{
    right: calc( ((1296 - 1648) / 1920) * 100vw);
  }
  &.hidden{
    right: calc( (-1608 / 1920) * 100vw);
  }

  .menu_pc{
    width: calc( (1508 / 1920) * 100vw);
    max-width: 1508px;
  }
  .menu_item{
    position: absolute;
    display: inline-block;
    top: calc( (180 / 1920) * 100vw);
    left: calc( (640 / 1920) * 100vw);
    height: calc( (50 / 1920) * 100vw);
    width: calc( (400 / 1920) * 100vw);
    transform: rotate(-5deg);
    cursor: pointer;
    &.about{
      height: calc( (50 / 1920) * 100vw);
      width: calc( (300 / 1920) * 100vw);
      top: calc( (480 / 1920) * 100vw);
      left: calc( (400 / 1920) * 100vw);
    }
    &.token{
      height: calc( (50 / 1920) * 100vw);
      width: calc( (300 / 1920) * 100vw);
      top: calc( (560 / 1920) * 100vw);
      left: calc( (410 / 1920) * 100vw);
    }
    &.buy{
      height: calc( (50 / 1920) * 100vw);
      width: calc( (300 / 1920) * 100vw);
      top: calc( (630 / 1920) * 100vw);
      left: calc( (420 / 1920) * 100vw);
    }
    &.join{
      height: calc( (60 / 1920) * 100vw);
      width: calc( (300 / 1920) * 100vw);
      top: calc( (920 / 1920) * 100vw);
      left: calc( (420 / 1920) * 100vw);
    }
  }
}

@media screen and (max-width: 750px) {
  .menu{
    &.h5.show{display: block;}
    &.pc.show{display: none;}

    background-image: none;
    background-color: #ffffff;
    border-radius: 20px 0 0 20px;
    width: calc( (1002 / 1080) * 100vw);
    height: 100vh;
    &.show{
      right: 0;
      top: 0;
    }
    &.hidden{
      right: calc( (-1022 / 1080) * 100vw);
      top: 0;
    }
    .top{
      display: flex;
      justify-content: flex-end;
      padding-right: calc( (90 / 1080) * 100vw);
      padding-top: calc( (40 / 1080) * 100vw);
      flex-direction: column;
      align-items: flex-end;

      .h5_logo{
        width: calc( (316 / 1080) * 100vw);
      }
      .back_button{
        display: flex;
        align-items: center;
        padding-top: calc( (30 / 1080) * 100vw);
        .back_icon{
          width: calc( (64 / 1080) * 100vw);
          margin-right: 4px;
        }
        span{
          font-family: zihun95hao;
          font-weight: normal;
          font-size: calc( (44 / 1080) * 100vw);
          color: #000000;
        }
      }
    }
    .nav{
      padding-left: 70px;
      padding-top: 70px;
      .nav_item{
        display: flex;
        align-items: center;
        margin-bottom: 36px;
        img{
          width: calc( (60 / 1080) * 100vw);
          max-width: 20px;
        }
        span{
          display: inline-block;
          font-family: zihun95hao;
          font-weight: normal;
          font-size: 22px;
          line-height: 22px;
          color: #000000;
          padding-left: calc( (46 / 1080) * 100vw);;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .scene{
      display: flex;
      justify-content: center;
      padding-top: 58px;
      .scene_item{
        img{
          width: calc( (388 / 1080) * 100vw);
          height: calc( (388 / 1080) * 100vw);
        }
        p{
          width: calc( (388 / 1080) * 100vw);
          height: calc( (38 / 1080) * 100vw);
          font-family: zihun95hao;
          font-weight: normal;
          font-size: calc( (34 / 1080) * 100vw);
          color: #000000;
          text-align: center;
        }
        &:first-child{
          margin-right: calc( (43 / 1080) * 100vw);
        }
      }
    }
    .bottom{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      position: absolute;
      bottom: 42px;
      width: 100%;
      img{
        width: calc( (557 / 1080) * 100vw);
        max-width: 186px;
      }
      p{
        width: 100%;
        text-align: center;
        font-family: zihun95hao;
        font-weight: normal;
        font-size: 20px;
        color: #000000;
      }
    }

  }
}

 
