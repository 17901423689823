.IndexPage{
  header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
    /* backdrop-filter: blur(2px); */
    .inner{
      max-width: 1920px;
      margin: 0 auto;
      .home_header{
        height: calc( (178 / 1920) * 100vw);
        padding: 0 36px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        box-sizing: border-box;
        .logo{
          width: calc( (421 / 1920) * 100vw);
          height: calc( (178 / 1920) * 100vw);
        }
        .menuButton{
          cursor: pointer;
          width: calc( (45 / 1920) * 100vw);
          height: calc( (45 / 1920) * 100vw);
        }
      }
      .subpage_header{
        height: calc( (178 / 1920) * 100vw );
        padding: 0 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 2;
        .left{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          .back_icon{
            width: calc( (45 / 1920) * 100vw );
          }
          span{
            font-family: zihun95hao;
            font-weight: normal;
            font-size: calc( (34 / 1920) * 100vw );
            color: #000000;
            line-height: 46px;
            margin-left: 8px;
          }
        }
        .right{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .logo{
            /* width: calc( (421 / 1920) * 100vw);
            height: calc( (178 / 1920) * 100vw); */
            width: calc( (320 / 1920) * 100vw);
          }
        }
        .menuButton{
          margin-left: 45px;
          cursor: pointer;
          width: calc( (45 / 1920) * 100vw);
          height: calc( (45 / 1920) * 100vw);
        }
      }

    }
  }
}

@media screen and (max-width: 750px) {
  .IndexPage{
    header{
      .inner{
        .home_header{
          height: calc( (178 / 1080) * 100vw);
          padding: 0 calc( (48 / 1080) * 100vw);
          .logo{
            width: calc( (421 / 1080) * 100vw);
            height: calc( (178 / 1080) * 100vw);
          }
          .menuButton{
            cursor: pointer;
            width: calc( (78 / 1080) * 100vw);
            height: calc( (78 / 1080) * 100vw);
          }
        }
        .subpage_header{
          height: auto;
          padding: calc( (40 / 1080) * 100vw ) calc( (48 / 1080) * 100vw ) 0;
          justify-content: flex-end;
          flex-direction: column;
          align-items: flex-end;
          .left{
            order: 2;
            padding-top: calc( (30 / 1080) * 100vw );
            .back_icon{
              width: calc( (64 / 1080) * 100vw );
            }
            span{
              font-size: calc( (68 / 1920) * 100vw );
              line-height: calc( (68 / 1920) * 100vw );
            }
          }
          .right{
            order: 1;
            .logo{
              width: calc( (329 / 1080) * 100vw);
            }
          }
          .menuButton{
            margin-left: calc( (45 / 1080) * 100vw);
            cursor: pointer;
            width: calc( (79 / 1080) * 100vw);
            height: calc( (79 / 1080) * 100vw);
          }
        }

      }
    }
  }
}